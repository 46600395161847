import store from '@/store';
import router from '@/router';
//
export function tokenExpirado(erro, mensagemErro) {
  if (erro.response && erro.response.status == 401) {
    localStorage.removeItem('token');
    router.push('/login');
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: 'Seu login expirou.',
      tipo: 'erro',
    });
  }
  if (erro.response && erro.response.status == 404) {
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: `${erro.response.status} - ${erro.response.data}`,
      tipo: 'erro',
    })
  }
  if (erro.response && erro.response.status == 400) { // Bad request
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: mensagemErro,
      tipo: 'erro',
    })
  }
  if (erro.response && erro.response.data == '') {
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: 'Nenhuma informação encontrada.',
      tipo: 'erro',
    });
  }
  if (erro.response && erro.response.data.message && erro.response.data.message != '') {
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: erro.response.data.message,
      tipo: 'erro',
    });
  }
  if (erro.response && mensagemErro) {
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: String(erro.response.data.mensagem),
      tipo: 'erro',
    });
  }
  if (!erro.status && mensagemErro) { // Too Many Requests
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: "Recurso bloqueado! Quantidade de requisições por minuto foi excedida. Tente novamente em 10 minutos.",
      tipo: 'erro',
    })
  }
  if (erro.response.data.errors.list[0] && mensagemErro) {
    return store.commit('SET_MODAL', {
      ativado: true,
      mensagem: String(erro.response.data.errors.list[0]),
      tipo: 'erro',
    });
  }
}
